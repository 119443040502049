@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3 {
  @apply font-bold;
}

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}

p {
  @apply text-base;
}

.crisp {
  image-rendering: crisp-edges;
}

.brand-gradient {
  @apply bg-gradient-to-br from-green-500 to-green-300;
}

.PhoneInputCountryIcon {
  @apply w-10;
}

.PhoneInputCountrySelect {
  @apply w-32;
}

/*Logo Animation*/

#logo:hover #logo-arm {
  animation: wave 1.2s 1;
}

@keyframes wave {
  0% {
    transform: rotate(0) translateX(0) translateY(0);
  }
  25% {
    transform: rotate(-5deg) translateX(-0.8em) translateY(0.5em);
  }
  50% {
    transform: rotate(10deg) translateX(1.6em) translateY(-1em);
  }
  75% {
    transform: rotate(-5deg) translateX(-0.8em) translateY(0.5em);
  }
  100% {
    transform: rotate(0) translateX(0) translateY(0);
  }
}
